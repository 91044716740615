/* eslint-disable max-lines */
export const section = {
  abacus: {
    aiEngineName: "abacus",
    entityId: "506035",
    entityUuid: "98cbd2d9-35e4-49bd-a3c2-e02f9fc9003d",
    name: "Abacus",
    routeType: "Section",
    urlAlias: "/abacus"
  },
  announcements: {
    entityId: "322430",
    entityUuid: "d9ccc819-964c-46e6-91f4-1e2593372396"
  },
  asia: {
    aiEngineName: "asia",
    eastAsia: {
      entityId: "318214",
      entityUuid: "c8707d7c-7e73-419b-bfc4-11d706ae7f41",
      name: "East Asia",
      urlAlias: "/news/asia/east-asia"
    },
    entityId: "3",
    name: "Asia",
    southAsia: {
      entityId: "318216",
      entityUuid: "b45de533-3772-473e-81e3-5f3e7304023c",
      name: "South Asia",
      urlAlias: "/news/asia/south-asia"
    }
  },
  betterLife: {
    aiEngineName: "better-life",
    entityId: "501963",
    entityUuid: "f0b06cdd-ec55-441a-bb2f-c112c567cd06",
    name: "Better life",
    routeType: "Section",
    urlAlias: "/better-life"
  },
  business: {
    aiEngineName: "business",
    bankingAndFinance: {
      entityId: "11",
      entityUuid: "4be0d942-930b-4da0-a8e4-a5c7be2c511c",
      name: "Banking & Finance",
      urlAlias: "/business/banking-finance"
    },
    chinaEVs: {
      entityId: "519589",
      entityUuid: "3bbec285-ccc6-4c08-85fe-7d3c8bae2c73",
      name: "China EVs",
      urlAlias: "/business/china-evs"
    },
    climateAndEnergy: {
      entityId: "519590",
      entityUuid: "51b85627-936a-4045-9b73-8109ff59987d",
      name: "Climate and energy",
      urlAlias: "/business/climate-and-energy"
    },
    companies: {
      entityId: "10",
      entityUuid: "37926364-2224-470b-a1bc-bbfe75c067e2",
      name: "Companies",
      urlAlias: "/business/companies"
    },
    cryptocurrency: {
      entityId: "517908",
      entityUuid: "3c5aeabc-19e9-4ab2-aa4c-e5b5663b0240",
      name: "Cryptocurrency",
      urlAlias: "/business/cryptocurrency"
    },
    entityId: "92",
    entityUuid: "718a073e-4006-4037-871e-6f6734324b4b",
    markets: {
      entityId: "318484",
      entityUuid: "00706fb0-59d1-47d4-a107-06c5dac67e82",
      name: "Markets",
      urlAlias: "/business/markets"
    },
    name: "Business",
    routeType: "Section",
    urlAlias: "/business"
  },
  china: {
    aiEngineName: "china",
    entityId: "4",
    name: "China"
  },
  chinaFutureTech: {
    aerospace: {
      entityId: "519739",
      entityUuid: "15004e80-5194-447c-96c2-f9a052857b8e",
      name: "Aerospace",
      urlAlias: "/news/china-future-tech/aerospace"
    },
    ai: {
      entityId: "519737",
      entityUuid: "47afd55b-4d5d-4a03-a512-5a15e5d795f4",
      name: "AI",
      urlAlias: "/news/china-growth-watch/ai"
    },
    aiEngineName: "china-future-tech",
    biomedicine: {
      entityId: "519741",
      entityUuid: "df3ad840-5f47-45bb-afd9-c4811965be03",
      name: "Biomedicine",
      urlAlias: "/news/china-future-tech/biomedicine"
    },
    entityId: "519735",
    entityUuid: "fb2c307f-c161-44b5-9e54-579fb3e64fb6",
    evs: {
      entityId: "519736",
      entityUuid: "0e4f3838-ad75-4084-834b-6f10cb9d5b1b",
      name: "EVs",
      urlAlias: "/news/china-future-tech/evs"
    },
    name: "China Future Tech",
    robotics: {
      entityId: "519740",
      entityUuid: "fc8745a4-2d5b-4e6f-94bb-8932887ec381",
      name: "Robotics",
      urlAlias: "/news/china-future-tech/robotics"
    },
    semiconductors: {
      entityId: "519738",
      entityUuid: "e06a69fb-2c1a-4867-9494-85c6912f6c9a",
      name: "Semiconductors",
      urlAlias: "/news/china-future-tech/semiconductors"
    }
  },
  chinaScience: {
    aiEngineName: "china",
    entityId: "328475",
    name: "China Science"
  },
  chinaTechCity: {
    aiEngineName: "china-tech-city",
    entityId: "501873"
  },
  comment: {
    aiEngineName: "comment",
    asiaOpinion: {
      entityId: "517927",
      entityUuid: "becec4a0-a924-4c7e-b2ff-abfb09592de1",
      name: "Asia Opinion",
      urlAlias: "/comment/asia-opinion"
    },
    chinaOpinion: {
      entityId: "517911",
      entityUuid: "091cecb9-c2cc-45ec-8291-4f66ed3ced11",
      name: "China Opinion",
      urlAlias: "/comment/china-opinion"
    },
    entityId: "93",
    entityUuid: "75878f6f-55b9-4675-8384-7dfcf67cd6b1",
    harrysView: {
      entityId: "76670",
      entityUuid: "caee4614-a67e-4710-9331-2e41261e636e",
      name: "Harry's View",
      routeType: "Section",
      urlAlias: "/comment/harrys-view"
    },
    letters: {
      entityId: "22",
      entityUuid: "a975107c-d167-41b3-8f74-7b8be3e935b0",
      name: "Letters",
      routeType: "Section",
      urlAlias: "/comment/letters"
    },
    name: "Comment",
    opinion: {
      entityId: "17",
      entityUuid: "2301d056-f8fd-4130-8768-1f1b1a40261f",
      name: "Opinion",
      routeType: "Section",
      urlAlias: "/comment/insight-opinion"
    },
    routeType: "Section",
    urlAlias: "/comment",
    worldOpinion: {
      entityId: "517912",
      entityUuid: "c97a0ecc-beff-4c1d-b71e-fb2091ea828d",
      name: "World Opinion",
      urlAlias: "/comment/world-opinion"
    }
  },
  cooking: {
    aiEngineName: "cooking",
    entityId: "502060",
    entityUuid: "36ed00d8-5455-4bf8-be71-5b841beefcca",
    name: "Cooking",
    routeType: "Section",
    urlAlias: "/cooking"
  },
  coronavirus: {
    aiEngineName: "coronavirus",
    entityId: "505323",
    entityUuid: "ab187950-76a6-419b-b548-a74cd4286f5f",
    name: "Coronavirus",
    routeType: "Section",
    urlAlias: "/coronavirus"
  },
  culture: {
    aiEngineName: "culture",
    entityId: "322296"
  },
  economy: {
    aiEngineName: "economy",
    chinaEconomy: {
      entityId: "318421",
      entityUuid: "1a3fffe6-1b59-446a-a806-9eca78f173e8",
      name: "China Economy",
      urlAlias: "/economy/china-economy"
    },
    economicIndicators: {
      entityId: "328480",
      entityUuid: "a0b32ef6-ef99-459a-8e42-2a508ec1d1b1",
      name: "Economic Indicators",
      urlAlias: "/economy/economic-indicators"
    },
    entityId: "328461",
    entityUuid: "4aa8843f-16e2-4705-99d6-b53f22f6f75a",
    globalEconomy: {
      entityId: "12",
      entityUuid: "6fb27df5-0939-449f-8766-eaf132d9e61e",
      name: "Global Economy",
      urlAlias: "/economy/global-economy"
    },
    name: "Economy",
    policy: {
      entityId: "519716",
      entityUuid: "7c4a0dee-2bcd-40e8-b81a-1d86f24c5a00",
      name: "Policy",
      urlAlias: "/economy/policy"
    },
    routeType: "Section",
    urlAlias: "/economy"
  },
  explained: {
    aiEngineName: "explained",
    entityId: "328486",
    entityUuid: "68445a51-2d2b-48dd-ba4b-1154bb1ffbec",
    name: "Explained",
    routeType: "Section",
    urlAlias: "/explained"
  },
  goldthread: {
    aiEngineName: "goldthread",
    entityId: "327251"
  },
  hongKong: {
    aiEngineName: "hong-kong",
    entityId: "2",
    name: "Hong Kong"
  },
  infographics: {
    aiEngineName: "infographics",
    entityId: "309585",
    entityUuid: "50c20b8d-5141-42a9-a2ed-19e8701da284",
    name: "Infographics",
    routeType: "Section",
    urlAlias: "/infographic"
  },
  knowledge: {
    aiEngineName: "knowledge",
    entityId: "507449",
    entityUuid: "e490b26d-0451-46fd-905a-5fbb45c0590a",
    name: "Knowledge",
    routeType: "Section",
    urlAlias: "/knowledge"
  },
  kPop: {
    aiEngineName: "k-pop",
    entityId: "507629",
    entityUuid: "4cd69b05-aa7d-406b-ba9e-9c645820bed7",
    name: "K-Pop",
    routeType: "Section",
    urlAlias: "/k-pop"
  },
  lawAndCrime: {
    entityId: "318211",
    entityUuid: "8af52903-fd3d-4916-a7e7-605ee9b302b0",
    name: "Law and Crime",
    routeType: "Section",
    urlAlias: "/news/hong-kong/law-and-crime"
  },
  lifestyle: {
    aiEngineName: "lifestyle",
    artsAndCulture: {
      entityId: "23",
      entityUuid: "11ab5d0c-7206-4ca7-a828-356b70e3ac01",
      name: "Arts & Culture",
      routeType: "Section",
      urlAlias: "/lifestyle/arts-culture"
    },
    entertainment: {
      entityId: "328490",
      entityUuid: "f1bd667f-3a0f-45b3-8c5c-5a938e5e48cf",
      name: "Entertainment",
      routeType: "Section",
      urlAlias: "/lifestyle/entertainment"
    },
    entityId: "94",
    entityUuid: "6354b86c-8bb8-47ef-b370-edf637245976",
    familyAndRelationships: {
      entityId: "29",
      entityUuid: "15a346ea-db3d-4200-a959-22327abeb0e8",
      name: "Family & Relationships",
      routeType: "Section",
      urlAlias: "/lifestyle/family-relationships"
    },
    fashionAndBeauty: {
      entityId: "28",
      entityUuid: "41c84846-843b-4ce5-86d1-2ca487e1cbda",
      name: "Fashion & Beauty",
      routeType: "Section",
      urlAlias: "/lifestyle/fashion-beauty"
    },
    foodAndDrink: {
      entityId: "30",
      entityUuid: "ecc2bdff-77e7-47e2-9345-c4e93bff7088",
      name: "Food & Drink",
      routeType: "Section",
      urlAlias: "/lifestyle/food-drink"
    },
    healthAndWellness: {
      entityId: "24",
      entityUuid: "9a663eba-7673-41bd-9bfa-292cfca7bbe2",
      name: "Health & Wellness",
      routeType: "Section",
      urlAlias: "/lifestyle/health-wellness"
    },
    hundredTopTables: {
      entityId: "517759",
      entityUuid: "ac6a33e5-7a29-4f6d-b062-030011689087",
      name: "100 Top Tables",
      routeType: "Section",
      urlAlias: "/lifestyle/100-top-tables"
    },
    name: "Lifestyle",
    routeType: "Section",
    travelAndLeisure: {
      entityId: "37",
      entityUuid: "20a2879a-cde5-486e-aabe-4b378c8eea39",
      name: "Travel & Leisure",
      routeType: "Section",
      urlAlias: "/lifestyle/travel-leisure"
    },
    urlAlias: "/lifestyle"
  },
  magazine: {
    entityId: "99",
    entityUuid: "65cd885c-0a6c-42c5-881e-0dc9fcdca455",
    name: "Magazines"
  },
  martialArts: {
    aiEngineName: "martial-arts",
    entityId: "501166"
  },
  news: {
    aiEngineName: "news",
    asia: {
      entityId: "3",
      entityUuid: "3d582821-f766-41ed-94d1-d4b316a10602",
      name: "Asia",
      routeType: "Section",
      urlAlias: "/news/asia"
    },
    china: {
      diplomacy: {
        entityId: "318199",
        entityUuid: "468c2e56-d097-48c0-a71e-beef686c0237",
        name: "Diplomacy",
        urlAlias: "/news/china/diplomacy"
      },
      entityId: "4",
      entityUuid: "08925cfe-01ce-4932-b9e2-be5b255efe63",
      name: "China",
      politics: {
        entityId: "318198",
        entityUuid: "613057c5-bfc5-482a-a9fc-8f3ea0425655",
        name: "Politics",
        urlAlias: "/news/china/politics"
      },
      routeType: "Section",
      urlAlias: "/news/china"
    },
    entityId: "91",
    entityUuid: "86e1c519-948e-4460-adc3-1dfafefa60da",
    hongKong: {
      entityId: "2",
      entityUuid: "465e2368-b5d9-4ab9-a471-e7ff921e6389",
      healthAndEnvironment: {
        entityId: "318208",
        entityUuid: "1491f48e-51e6-4125-b698-7495d415830a",
        name: "Health & Environment",
        routeType: "Section",
        urlAlias: "/news/hong-kong/health-environment"
      },
      hongKongEconomy: {
        entityId: "318210",
        entityUuid: "f58386da-5899-40ec-8630-f72aa3e88bb2",
        name: "Hong Kong economy",
        routeType: "Section",
        urlAlias: "/news/hong-kong/hong-kong-economy"
      },
      name: "Hong Kong",
      politics: {
        entityId: "318206",
        entityUuid: "d29401d8-4a71-45bc-988c-5199cfd23dc8",
        name: "Politics",
        urlAlias: "/news/hong-kong/politics"
      },
      routeType: "Section",
      urlAlias: "/news/hong-kong"
    },
    name: "News",
    peopleAndCulture: {
      chinaPersonalities: {
        entityId: "508148",
        entityUuid: "7ab185d0-409b-4c5a-b250-aa984bc6d0e4",
        name: "China Personalities",
        urlAlias: "/news/people-culture/china-personalities"
      },
      entityId: "318202",
      entityUuid: "c3524c79-f0d5-48fd-b834-36a56894c672",
      environment: {
        entityId: "508151",
        entityUuid: "bebf2949-108a-4a53-a914-30dd3c0bf683",
        name: "Environment",
        urlAlias: "/news/people-culture/environment"
      },
      genderAndDiversity: {
        entityId: "508150",
        entityUuid: "823db65f-8839-47fd-904f-d7652a74829e",
        name: "Gender & Diversity",
        urlAlias: "/news/people-culture/gender-diversity"
      },
      name: "People & Culture",
      routeType: "Section",
      socialWelfare: {
        entityId: "508152",
        entityUuid: "29708aa9-6509-4401-9550-3999df3563e8",
        name: "Social Welfare",
        urlAlias: "/news/people-culture/social-welfare"
      },
      trendingInChina: {
        entityId: "508149",
        entityUuid: "c430ed8f-bbcf-4299-bd61-419d06250fd4",
        name: "Trending in China",
        urlAlias: "/news/people-culture/trending-china"
      },
      urlAlias: "/news/people-culture"
    },
    routeType: "Section",
    urlAlias: "/news",
    world: {
      entityId: "5",
      entityUuid: "fc2e7dc2-9791-410a-906c-28ae8a6cf9db",
      name: "World",
      routeType: "Section",
      urlAlias: "/news/world"
    }
  },
  olympic: {
    aiEngineName: "paris-olympics",
    entityId: "518881",
    entityUuid: "6418d821-6d87-4135-9059-f76017b37a6b",
    name: "Paris Olympics 2024",
    routeType: "Section",
    urlAlias: "/paris-olympics"
  },
  peopleCulture: {
    aiEngineName: "people-culture",
    entityId: "318202",
    name: "People & Culture"
  },
  photo: {
    entityId: "97",
    entityUuid: "38b04fac-790c-4dcc-a214-23c6f592ae4a",
    harrysView: {
      entityId: "324599",
      entityUuid: "ee4a523c-0f17-4109-b503-8a907f05537b",
      name: "Harry's View",
      routeType: "Section",
      urlAlias: "/photos/harrys-view"
    },
    name: "Pictures",
    routeType: "Section",
    urlAlias: "/photos"
  },
  podcasts: {
    aiEngineName: "podcasts",
    entityId: "328485",
    entityUuid: "84508dfa-40c3-4571-bc32-da1db88f67df",
    name: "Podcasts",
    routeType: "Section",
    urlAlias: "/podcasts"
  },
  posties: {
    entityId: "515415",
    entityUuid: "2a786249-ee3e-4fda-9991-0d757340f9a7",
    name: "Posties",
    quiz: {
      entityId: "520863",
      entityUuid: "44de92f1-c1fa-439f-83a1-68978d14a10a",
      name: "Quizzes Zone",
      urlAlias: "/posties/quizzes-zone"
    },
    read: {
      entityUuid: "4f55e50d-8d3e-438b-89a0-98e39cb9b66b"
    },
    routeType: "Section",
    urlAlias: "/posties"
  },
  postMagazine: {
    aiEngineName: "post-magazine",
    artsAndMusic: {
      entityId: "321713",
      entityUuid: "64b859a8-cd16-4cf5-b6f3-ef77077c152a",
      name: "Arts & Music",
      routeType: "Section",
      urlAlias: "/postmag/arts-music"
    },
    books: {
      entityId: "321720",
      entityUuid: "34611753-4ea8-4c58-97ca-0099c41f66d1",
      name: "Books",
      routeType: "Section",
      urlAlias: "/postmag/books"
    },
    culture: {
      entityId: "519633",
      entityUuid: "bd2e1eba-8d41-424d-b611-68f20f64b42a",
      name: "Culture",
      routeType: "Section",
      urlAlias: "/postmag/culture"
    },
    designAndInteriors: {
      entityId: "322659",
      entityUuid: "70b76c33-b04a-4126-894b-71c93092a288",
      name: "Design & Interiors",
      routeType: "Section",
      urlAlias: "/postmag/design-interiors"
    },
    entityId: "71",
    entityUuid: "6905550f-cf81-405a-8a19-6ffd324a9bb0",
    fashion: {
      entityId: "321719",
      entityUuid: "9e2b2483-6738-42a7-851d-9593624f00c6",
      name: "Fashion",
      routeType: "Section",
      urlAlias: "/postmag/fashion"
    },
    foodAndDrink: {
      entityId: "321716",
      entityUuid: "44f3d548-abb6-482c-878b-068e06f5bf1a",
      name: "Food & Drink",
      routeType: "Section",
      urlAlias: "/postmag/food-drink"
    },
    longReads: {
      entityId: "322658",
      entityUuid: "07cbf524-3aa6-4201-b3f3-28c276b92cd3",
      name: "Long Reads",
      routeType: "Section",
      urlAlias: "/postmag/long-reads"
    },
    name: "PostMag",
    passions: {
      entityId: "519635",
      entityUuid: "f4d37181-157e-49b3-8fcd-f708e0c89437",
      name: "Passions",
      routeType: "Section",
      urlAlias: "/postmag/passions"
    },
    routeType: "Section",
    shortReads: {
      entityId: "323244",
      entityUuid: "c9080138-966e-498b-a9bf-c21303061b04",
      name: "Short Reads",
      routeType: "Section",
      urlAlias: "/postmag/short-reads"
    },
    travel: {
      entityId: "321721",
      entityUuid: "13262829-8186-44aa-afc8-71f80fffec36",
      name: "Travel",
      routeType: "Section",
      urlAlias: "/postmag/travel"
    },
    urlAlias: "/postmag",
    wellness: {
      entityId: "519634",
      entityUuid: "ea83bb36-7a6d-4218-8377-ae26348557c7",
      name: "Wellness",
      routeType: "Section",
      urlAlias: "/postmag/wellness"
    }
  },
  property: {
    aiEngineName: "property",
    entityId: "96"
  },
  racing: {
    entityId: "39"
  },
  recap: {
    aiEngineName: "recap",
    entityId: "519775",
    entityUuid: "0bc769b5-3c72-4eb4-a7c5-93da61071650",
    name: "recap",
    routeType: "Section",
    urlAlias: "/recap"
  },
  rugby: {
    aiEngineName: "rugby",
    entityId: "310895"
  },
  scmpFilms: {
    // Treat drupal video sections as sections since content service does not include video section
    entityId: "329005",
    entityUuid: "3fff713e-a6ed-464b-989a-014ddad7952d",
    name: "SCMP Films",
    routeType: "Section",
    urlAlias: "/scmp-films"
  },
  scmpOriginals: {
    entityId: "315555"
  },
  scmpPrBlog: {
    entityId: "512548",
    entityUuid: "95938fd2-5c1b-41a8-bccb-c4550723ed25"
  },
  sport: {
    aiEngineName: "sport",
    entityId: "95",
    entityUuid: "078620c2-f6d7-4b1f-872e-7b4083c87e38",
    name: "Sport",
    outdoor: {
      aiEngineName: "outdoor",
      entityId: "324931"
    },
    racing: {
      entityId: "39"
    },
    routeType: "Section",
    urlAlias: "/sport"
  },
  style: {
    aiEngineName: "style",
    beauty: {
      entityId: "516298",
      name: "Beauty",
      urlAlias: "/magazines/style/beauty"
    },
    celebrity: {
      entityId: "322898",
      name: "Celebrity",
      urlAlias: "/magazines/style/celebrity"
    },
    entertainment: {
      entityId: "515034",
      urlAlias: "/lifestyle/entertainment"
    },
    entityId: "72",
    entityUuid: "9891fb45-50fa-4d5c-9210-f8a2597dec09",
    fashion: {
      entityId: "516294",
      name: "Fashion",
      urlAlias: "/magazines/style/fashion"
    },
    leisure: {
      entityId: "322899",
      name: "Leisure",
      urlAlias: "/magazines/style/leisure"
    },
    lifestyle: {
      entityId: "516305",
      name: "Lifestyle",
      urlAlias: "/magazines/style/lifestyle"
    },
    luxury: {
      entityId: "322897",
      name: "Luxury",
      urlAlias: "/magazines/style/luxury"
    },
    name: "Style",
    newsTrends: {
      entityId: "322896",
      name: "News & Trends",
      urlAlias: "/magazines/style/news-trends"
    },
    people: {
      entityId: "516301",
      name: "People",
      urlAlias: "/magazines/style/people"
    },
    routeType: "Section",
    techDesign: {
      entityId: "322901",
      name: "Tech & Design",
      urlAlias: "/magazines/style/tech-design"
    },
    urlAlias: "/magazines/style",
    watches: {
      entityId: "318256",
      urlAlias: "/magazines/style/watches"
    },
    watchesJewellery: {
      entityId: "322900",
      urlAlias: "/magazines/style/watches-jewellery"
    },
    wellBeing: {
      entityId: "501517",
      name: "Well-Being",
      urlAlias: "/magazines/style/well-being"
    }
  },
  talkingPost: {
    aiEngineName: "talking-post",
    entityId: "511121",
    entityUuid: "8548b1fa-b79c-4124-b2f4-63f3ca3affd4",
    name: "Talking Post",
    routeType: "Section",
    urlAlias: "/talking-post"
  },
  tech: {
    aiEngineName: "tech",
    bigTech: {
      entityId: "320663",
      entityUuid: "2a8cf737-8761-40d1-8c7d-633b0cef52b3",
      name: "Big Tech",
      routeType: "Section",
      urlAlias: "/tech/big-tech"
    },
    entityId: "36",
    entityUuid: "068377d8-31c9-4a54-bf7f-47b4892b5308",
    name: "Tech",
    policy: {
      entityId: "328479",
      entityUuid: "44a71a7e-7a11-406a-838a-28000db825d5",
      name: "Policy",
      routeType: "Section",
      urlAlias: "/tech/policy"
    },
    routeType: "Section",
    techTrends: {
      entityId: "508727",
      entityUuid: "c0aa02cb-28ec-4d26-82fe-ca26bb520fb6",
      name: "Tech Trends",
      routeType: "Section",
      urlAlias: "/tech/tech-trends"
    },
    techWar: {
      entityId: "508726",
      entityUuid: "6673c722-72a0-432c-a5a8-5c2d00bf7d84",
      name: "Tech War",
      routeType: "Section",
      urlAlias: "/tech/tech-war"
    },
    urlAlias: "/tech"
  },
  thisWeekInAsia: {
    aiEngineName: "twia",
    economics: {
      entityId: "323048",
      entityUuid: "bf62d0af-fda6-467e-a572-25fea3a60255",
      name: "Economics",
      routeType: "Section",
      urlAlias: "/week-asia/economics"
    },
    entityId: "323045",
    entityUuid: "94de9d0d-5695-4d44-96d4-3d3e0f2cd73d",
    explained: {
      entityId: "328489",
      entityUuid: "dda01aec-24be-453b-ae3f-5a59da25b8b3",
      name: "Explained",
      routeType: "Section",
      urlAlias: "/week-asia/explained"
    },
    healthEnvironment: {
      entityId: "328487",
      entityUuid: "5f4f2fbd-96b3-4f9c-ad90-a6119cb7cae6",
      name: "Health & Environment",
      routeType: "Section",
      urlAlias: "/week-asia/health-environment"
    },
    lifestyleAndCulture: {
      entityId: "328488",
      entityUuid: "dd8eb76a-1ae9-4686-9226-8cd0195b13d8",
      name: "Lifestyle & Culture",
      routeType: "Section",
      urlAlias: "/week-asia/lifestyle-culture"
    },
    name: "This Week in Asia",
    opinion: {
      entityId: "323050",
      entityUuid: "a9e9e155-f6cb-4c50-9f01-5df69e2594b9",
      name: "Opinion",
      routeType: "Section",
      urlAlias: "/week-asia/opinion"
    },
    people: {
      entityId: "323052",
      entityUuid: "210b727d-9d62-47b6-8b33-3ce418383ead",
      name: "People",
      routeType: "Section",
      urlAlias: "/week-asia/people"
    },
    politics: {
      entityId: "323046",
      entityUuid: "abadb127-eda3-4fc4-a8b4-91a6a2b001f5",
      name: "Politics",
      routeType: "Section",
      urlAlias: "/week-asia/politics"
    },
    routeType: "Section",
    urlAlias: "/week-asia"
  },
  us: {
    entityId: "519916",
    entityUuid: "576e6375-650f-48e9-b8af-c3844b06b308",
    name: "US",
    routeType: "Section",
    urlAlias: "/news/us",
    usElections: {
      analysis: {
        entityId: "519919",
        entityUuid: "57aee850-2280-4e3d-9e5e-e7ab50ac0fe8",
        name: "Analysis",
        urlAlias: "/news/us/elections/analysis"
      },
      entityId: "519917",
      entityUuid: "8db846eb-6397-4433-99cd-f4f6851d6d54",
      explainers: {
        entityId: "519920",
        entityUuid: "4fcbb3a8-bd20-48b5-b9db-497e4a0e839b",
        name: "Explainers",
        urlAlias: "/news/us/elections/explainers"
      },
      latest: {
        entityId: "519918",
        entityUuid: "8c7dadff-f62b-4969-91a9-ee2c348d3d54",
        name: "latest",
        urlAlias: "/news/us/elections/latest"
      },
      name: "US Elections",
      urlAlias: "/news/us/us-elections"
    }
  },
  video: {
    entityId: "98",
    entityUuid: "5196ffc0-4820-4956-9def-e60eed26ee68",
    name: "Video",
    podcasts: {
      entityId: "515586",
      entityUuid: "b745d713-85a9-46f5-8408-3df710884d5d",
      name: "Podcasts",
      urlAlias: "/video/podcasts"
    },
    posties: {
      entityId: "515863",
      entityUuid: "73954174-2ab3-42b4-b6cc-e60e4a49e56c",
      name: "Posties",
      urlAlias: "/video/posties"
    },
    routeType: "Section",
    urlAlias: "/video",
    yp: {
      entityId: "503705",
      entityUuid: "6158f4fe-20d4-4319-b685-f4b59fbb7e08",
      name: "Young Post",
      urlAlias: "/video/yp"
    }
  },
  weather: {
    aiEngineName: "weather",
    entityId: "579"
  },
  world: {
    africa: {
      entityId: "322266",
      entityUuid: "78e06e52-84e3-453d-8981-7a7c15eb37ed",
      name: "Africa",
      urlAlias: "/news/world/africa"
    },
    aiEngineName: "world",
    americas: {
      entityId: "322265",
      entityUuid: "2e950a63-933f-4d2a-b124-7e353fa31a6c",
      name: "Americas",
      urlAlias: "/news/world/americas"
    },
    entityId: "5",
    europe: {
      entityId: "322263",
      entityUuid: "fbf86ef6-754f-4053-83eb-75c979d475b5",
      name: "Europe",
      urlAlias: "/news/world/europe"
    },
    middleEast: {
      entityId: "322264",
      entityUuid: "b248c9ed-a9cd-4d69-b82e-30281acf5c85",
      name: "Middle East",
      urlAlias: "/news/world/middle-east"
    },
    name: "World",
    russiaCentralAsia: {
      entityId: "322514",
      entityUuid: "02557295-c0c4-49e1-b8d4-33e67dbe0c33",
      name: "Russia & Central Asia",
      urlAlias: "/news/world/russia-central-asia"
    },
    unitedStatesCanada: {
      entityId: "322262",
      entityUuid: "014ee494-4f4f-42ce-9286-a339b02fa56f",
      name: "United States & Canada",
      urlAlias: "/news/world/united-states-canada"
    }
  },
  yp: {
    aiEngineName: "yp",
    entityId: "503603",
    entityUuid: "c53d30d7-9375-4516-869f-8e62e130b2bd",
    name: "YP"
  }
};